// @ts-check
export const CLS_FACTOR = 10000;
export const PIXEL_RATIO_FACTOR = 10;
export const MAX_DOWNLOAD_SPEED = 10000;

export const MARKER = '\u23f1'; // stopwatch

export const LISTEN = {
  capture: true,
  passive: true,
};

export const LISTEN_ONCE = {
  ...LISTEN,
  once: true,
};
